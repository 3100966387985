var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticStyle: { "flex-wrap": "wrap" },
      attrs: { "fill-height": "", fluid: "" },
    },
    [
      _c(
        "material-card",
        { attrs: { color: "green" } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("div", { staticClass: "title font-weight-light mb-2" }, [
              _vm._v("Material Design Icons"),
            ]),
            _c("div", { staticClass: "category font-weight-thin" }, [
              _vm._v("\n        See all available\n        "),
              _c(
                "a",
                {
                  staticClass: "white--text",
                  attrs: {
                    href: "https://materialdesignicons.com/",
                    target: "_blank",
                  },
                },
                [_vm._v("\n          Icons\n        ")]
              ),
            ]),
          ]),
          _c(
            "v-layout",
            { attrs: { "align-center": "", "justify-center": "", wrap: "" } },
            _vm._l(_vm.icons, function (icon) {
              return _c(
                "v-flex",
                { key: icon, attrs: { "ma-2": "" } },
                [
                  _c(
                    "v-tooltip",
                    { attrs: { top: "", "content-class": "top" } },
                    [
                      _c(
                        "v-icon",
                        { attrs: { slot: "activator" }, slot: "activator" },
                        [
                          _vm._v(
                            "\n            " + _vm._s(icon) + "\n          "
                          ),
                        ]
                      ),
                      _c("span", [_vm._v(_vm._s(icon))]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mx-auto" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mx-0 font-weight-light",
              attrs: {
                large: "",
                color: "success",
                href: "https://materialdesignicons.com/",
                target: "_blank",
                round: "",
              },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("mdi-material-design"),
              ]),
              _c("span", [_vm._v("See all icons")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }